<!-- 报告预览页面 -->
<template>
  <div class="preview-container">
    <div v-if="!errorView">
      <div class="title">
        <span class="text">{{ previewData.filename }}</span>
      </div>
      <generateReportComp
        v-if="previewData"
        :viewData="previewData"
        :previewState="true"></generateReportComp>
    </div>
    <div class="error-view" v-else>
      没有找到对应的报表或链接已失效，请联系服务人员
    </div>
  </div>
</template>

<script>
export default {
  name: "reportPreview",
  components: {
    generateReportComp: () => import("@/components/generateReport"),
  },
  data() {
    return {
      errorView: false,
      token: "",
      previewData: null,
    };
  },
  created() {
    this.token = this.$route.params.token;
    if (this.token) {
      this.analysisFunc();
    } else {
      this.errorView = this.token === "";
    }
  },
  methods: {
    analysisFunc() {
      this.utils.sessionSetStore("token", this.token);
      this.$api
        .parseTokenFunc({ token: this.token })
        .then((res) => {
          console.log("token", res);
          this.previewData = res.data.object;
          this.errorView = false;
        })
        .catch((err) => {
          console.error(err);
          this.errorView = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 40px;
  position: relative;

  .title {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 2001;
    text-align: center;
    line-height: 40px;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    background: linear-gradient(-90deg, #0a48d1 0%, #1183fb 100%);
    .text {
      font-size: 20px;
      color: #fff;
    }
  }

  .error-view {
    text-align: center;
    font-size: 20px;
    color: #666;
    padding-top: 100px;
  }
}
</style>
