<!-- 报表简评 -->
<template>
  <div class="brief-comment">
    <div class="header">
      <div class="title">报表简评</div>
    </div>
    <div class="text-container">
      <div class="edit-text" v-if="editState">
        <el-input
          placeholder="编辑简评"
          type="textarea"
          v-model="reportText"
          :rows="8"></el-input>
        <div class="handle">
          <el-button type="primary" size="small" @click="saveHandle"
            >立即保存</el-button
          >
          <el-button size="small" @click="editState = false">取消</el-button>
        </div>
      </div>
      <div class="text-pre" v-else>
        <div class="text-content" v-if="reportText">
          <p v-for="(item, index) in reportText.split('\n')" :key="index">
            {{ item }}
          </p>
        </div>
        <div class="text-content" v-else>
          <p>暂无简评内容</p>
        </div>
        <el-button
          v-if="!previewState"
          size="small"
          class="edit-btn"
          @click="editState = true"
          >编辑</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "briefComment",
  props: ["viewData", "previewState"],
  watch: {
    "viewData.history_id": {
      handler: function () {
        this.getComment();
      },
      immediate: true,
    },
  },
  data() {
    return {
      reportText: "",
      editState: false,
    };
  },

  methods: {
    getComment() {
      this.$api
        .getBriefCommentFunc({
          history_id: this.viewData.history_id,
          brand_id: this.viewData.brand_id,
        })
        .then((res) => {
          console.log("-========", res);
          this.reportText = res.data.comment;
        });
    },
    saveHandle() {
      let paramObj = {
        history_id: this.viewData.history_id,
        brand_id: this.viewData.brand_id,
        comment: this.reportText,
      };
      this.$api.editCommentFunc(paramObj).then((res) => {
        this.$notify.success({
          title: "消息",
          message: "已保存简评",
          duration: 1000,
        });
        this.editState = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.brief-comment {
  padding-top: 20px;
  margin-bottom: 40px;
  .header {
    position: relative;
    height: 50px;
    .title {
      color: #fff;
      font-size: 18px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 2px 50px 2px 20px;
      border-radius: 0 0 50px 0;
      background: linear-gradient(270deg, #0a48d1, #1183fb);
    }
  }
  .text-container {
    .text-pre {
      .edit-btn {
        margin-top: 20px;
      }
      .text-content {
        > p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .edit-text {
      .handle {
        margin-top: 10px;
      }
    }
  }
}
</style>
