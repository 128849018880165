<!-- 周报生成图表功能 -->
<template>
  <div class="generate-report-comp">
    <el-row :gutter="20">
      <el-col :span="12">
        <!-- 挽回损失（侵权处理总销售额） -->
        <RecoverDamage
          :brand_id="viewData.brand_id"
          :history_id="viewData.history_id"></RecoverDamage>
      </el-col>
      <el-col :span="12">
        <!-- 店铺侵权数量排行榜 -->
        <ShopInfringeRanking
          :brand_id="viewData.brand_id"
          :history_id="viewData.history_id"></ShopInfringeRanking>
      </el-col>
    </el-row>

    <!-- 各平台破价率 -->
    <div style="margin-top: 10px">
      <PltBreakPercentage
        :brand_id="viewData.brand_id"
        :history_id="viewData.history_id"></PltBreakPercentage>
    </div>

    <el-row>
      <el-col :span="12">
        <div class="handling-plt">
          <!-- 各平台（通知、下架、整改、投诉量） -->
          <HandlingPlt
            :brand_id="viewData.brand_id"
            :history_id="viewData.history_id"></HandlingPlt>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="complaint-list">
          <!-- 非/白名单   通知量/投诉量 -->
          <ComplaintWhiteList
            :brand_id="viewData.brand_id"
            :history_id="viewData.history_id"></ComplaintWhiteList>
        </div>
      </el-col>
    </el-row>

    <!-- 投诉手段占比 -->
    <div>
      <CurBrandMeanOfComplaint
        :brand_id="viewData.brand_id"
        :history_id="viewData.history_id"></CurBrandMeanOfComplaint>
    </div>
    <div class="comment">
      <BriefComment
        :viewData="viewData"
        :previewState="previewState"></BriefComment>
    </div>
  </div>
</template>
<script>
export default {
  name: "generateReportComp",
  props: {
    viewData: {
      type: Object,
      required: true,
    },
    previewState: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ShopInfringeRanking: () => import("./components/shopInfringeRanking.vue"), // 店铺侵权数量排行榜
    PltBreakPercentage: () => import("./components/pltBreakPercentage.vue"), // 各平台破价率
    RecoverDamage: () => import("./components/recoverDamage.vue"), // 挽回损失（侵权处理总销售额）
    HandlingPlt: () => import("./components/handlingPlt.vue"), // 各平台（通知、下架、整改、投诉量）
    ComplaintWhiteList: () => import("./components/complaintWhiteList.vue"), // 非白名单
    CurBrandMeanOfComplaint: () =>
      import("./components/curBrandMeanOfComplaint.vue"), // 投诉手段占比
    BriefComment: () => import("./components/briefComment.vue"), // 简要评价
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.generate-report-comp {
  padding: 20px;
  height: calc(100% - 40px); // 减去表头高度
  overflow-y: auto;
  background-color: #fff;
  > div {
    height: 400px;
  }

  .handling-plt,
  .complaint-list {
    height: 400px;
  }
  .comment {
    height: auto;
  }
}
</style>
