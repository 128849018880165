<template>
  <div class="generate-report">
    <!-- 操作面板 -->

    <div class="handle-panel">
      <div class="btn">
        <span>报表记录</span>
        <div class="upload-handle">
          <el-button class="btn-upload" size="mini" icon="el-icon-upload2"
            >生成报表</el-button
          >
          <input
            type="file"
            class="upload"
            ref="refUpload"
            @change="importHandle" />
        </div>
      </div>
      <!-- 历史记录 -->
      <div class="history-report">
        <div class="his-content">
          <div v-if="total > 0">
            <div class="his-item" v-for="item in uploadRecord" :key="item.id">
              <!-- 文件名 -->
              <p class="name">{{ item.filename }}</p>
              <!-- 上传时间 -->
              <p class="time">{{ item.create_time }}</p>
              <!-- 操作按钮 -->
              <div class="btn-group">
                <div @click="deleteReport(item)">删除</div>
                <div @click="viewReport(item)">查看</div>
              </div>
            </div>
          </div>
          <div v-else>
            <el-empty description="没有记录"></el-empty>
          </div>
        </div>
        <div class="page-config">
          <el-pagination
            layout="prev, pager, next,total"
            :current-page="pageNow"
            @current-change="pageChange"
            :total="total"
            small
            :pager-count="5">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 报表看板 -->
    <div class="report-board">
      <div class="no-data" v-if="currentReportObj === null">
        <el-empty :image-size="200" description="请选择报表进行查看"></el-empty>
      </div>
      <div v-else>
        <div class="header">
          <span class="title">报表名称：{{ currentReportObj.filename }}</span>
          <el-button
            :load="shareLoading"
            @click="generateShareLink"
            size="small"
            icon="el-icon-link"
            type="primary"
            >生成分享链接(7天时效)</el-button
          >
        </div>
        <generateReportComp :viewData="currentReportObj"></generateReportComp>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "generateReport",
  props: {
    // 当前品牌
    currentBrand: {
      type: Number,
    },
  },
  components: {
    generateReportComp: () => import("@/components/generateReport"),
  },
  watch: {
    currentBrand() {
      this.currentReportObj = null;
      this.getRecord();
    },
  },
  data() {
    return {
      uploadRecord: [],
      currentReportObj: null, // 当前查看的报表
      pageNow: 1,
      pageSize: 10,
      total: 0,
      shareLoading: false,
    };
  },
  computed: {
    projectUrl() {
      // let baseUrl =
      return process.env.NODE_ENV === "development"
        ? `http://192.168.1.21:9527/report_preview`
        : `https://crm.baibo360.com/report_preview`;
      // return baseUrl + "/report_preview";
    },
  },
  created() {
    this.getRecord();
  },
  methods: {
    deleteReport(data) {
      this.$confirm("确定删除该报表吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .deleteReportRecord({
              brand_id: data.brand_id,
              history_id: data.history_id,
            })
            .then((res) => {
              console.log(res);
              this.$message.success("已删除");
              this.getRecord();
              if (data.history_id === this.currentReportObj.history_id) {
                this.currentReportObj = null;
              }
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    importHandle(e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("brandId", this.currentBrand);
      formData.append("loginId", this.utils.sessionGetStore("userId"));
      this.$refs.refUpload.value = null;

      const loading = this.$loading({
        lock: true,
        text: "报表生成中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$api
        .uploadReportFunc(formData)
        .then((res) => {
          console.log(res);
          this.getRecord("show");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("报表生成失败");
        })
        .finally(() => {
          loading.close();
        });
    },
    // 选择报表记录进行查看
    viewReport(data) {
      this.currentReportObj = data;
    },
    getRecord(autoView = false) {
      if (autoView) {
        // 生成报表后，重置页码，并自动展示最新一次
        this.pageNow = 1;
      }
      this.$api
        .getRecordUploadList({
          brand_id: this.currentBrand,
          pageNow: this.pageNow,
          pageSize: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          this.uploadRecord = res.data.list;
          this.total = res.data.count;
          if (autoView) {
            this.currentReportObj = this.uploadRecord[0];
          }
        });
    },
    pageChange(val) {
      this.pageNow = val;
      this.getRecord();
    },
    generateShareLink() {
      let paramObj = {
        brand_id: this.currentBrand,
        history_id: this.currentReportObj.history_id,
        days: 7,
      };
      this.shareLoading = true;
      this.$api
        .generateShareLinkFunc(paramObj)
        .then((res) => {
          console.log(res);
          let shareLink = this.projectUrl + "/" + res.data.Token;
          navigator.clipboard.writeText(shareLink).then(() => {
            this.$notify.success({ message: "分享链接已复制到剪贴板" });
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("生成分享链接失败，请重试");
        })
        .finally(() => {
          this.shareLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.generate-report {
  display: flex;
  align-items: flex-start; // 使子元素高度不自动撑满

  .handle-panel {
    width: 250px;
    background: #fff;
    margin-right: 15px;
    .btn {
      height: 50px;
      border-bottom: 1px solid #e0e0e0;
      padding: 0 10px;
      line-height: 50px;
      > span {
        font-size: 15px;
        font-weight: bold;
      }

      .upload-handle {
        float: right;

        display: inline;
        position: relative;
        .btn-upload {
          // margin-top: 10px;
        }
        .upload {
          width: 97px;
          height: 29px;
          position: absolute;
          left: 0;
          top: 10px;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    .history-report {
      padding: 20px 10px;
      .his-content {
        background-color: #f7f7f7;
        border: 1px solid #e0e0e0;
        min-height: 200px;
        max-height: 500px;
        padding: 10px;
        overflow: hidden;
        overflow-y: auto;
        .his-item {
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          margin-bottom: 10px;
          .name {
            line-height: 25px;
            padding: 0 5px;
          }
          .time {
            line-height: 25px;
            padding: 0 5px;
          }
          .btn-group {
            height: 25px;
            display: flex;
            border-top: 1px solid #e0e0e0;
            > div {
              width: 50%;
              text-align: center;
              line-height: 25px;
              cursor: pointer;
              &:nth-child(1) {
                border-right: 1px solid #e0e0e0;
                &:hover {
                  color: #fff;
                  background-color: #ff3e34;
                }
              }
              &:nth-child(2) {
                &:hover {
                  color: #fff;
                  background-color: #34aeff;
                }
              }
            }
          }
        }
      }
    }
  }

  .report-board {
    height: calc(100vh - 124px);
    flex: 1;
    background: #fff;

    .header {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin: 0 25px;
      }
    }
  }
}
</style>
