<!-- 侵权处理总销售额 / 挽回损失 -->
<!-- 各店铺侵权金额统计 -->
<!-- 再列出中侵权销售额总和 -->
<template>
  <div class="recover-damage">
    <div class="recover-price-sum">
      挽回总金额：<countTo
        :startVal="0"
        :endVal="grossAmount"
        :duration="800"
        class="num"></countTo>
    </div>
    <tablePageComp
      :dataList="dataList"
      :totalCount="total"
      :columnsList="columnsList"
      :getDataConfig="dataQueryFunc()"
      :updateDataTag="updateDataTag"
      :pageSizeConfig="{
        layoutConfig: 'prev,pager,next',
        pageSize: 10,
        'pager-count': 5,
      }"
      :tableHeight="300">
    </tablePageComp>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  name: "recoverDamage",
  props: ["brand_id", "history_id"],
  components: {
    countTo,
  },
  watch: {
    history_id() {
      this.updateDataTag = !this.updateDataTag;
    },
  },
  data() {
    return {
      dataList: [],
      total: 0,
      grossAmount: 0,
      updateDataTag: false,
      columnsList: [
        {
          label: "平台",
          prop: "platform",
        },
        {
          label: "店铺名称",
          prop: "shop_name",
        },
        {
          label: "侵权销售额",
          prop: "total_sales",
        },
      ],
    };
  },
  methods: {
    dataQueryFunc() {
      let paramObj = {
        brand_id: this.brand_id,
        history_id: this.history_id,
      };
      return {
        apiName: "getRecoverDamage",
        paramObj,
        success: function (res, callback) {
          console.log("reddedeed", res);
          this.dataList = res.data.list;
          this.grossAmount = parseInt(res.data.retrieve_money);
          this.total = res.data.count;
          callback();
        }.bind(this),
        failed: function (err, callback) {
          console.log(err);
          callback();
        }.bind(this),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.recover-price-sum {
  font-size: 20px;
  font-family: "Times New Roman";
  height: 50px;
  line-height: 50px;
  .num {
    font-size: 20px;
    color: #4992ff;
  }
}
</style>
