<!-- 服务模块 -->
<template>
  <div class="service">
    <!-- 二级导航 -->
    <div class="second-nav">
      <!-- 当前品牌 -->
      <div class="brand">
        <div class="b_style">
          <span>当前品牌：</span>
          <el-select
            size="mini"
            class="b_select"
            v-model="currentBrand"
            @change="brandChange">
            <el-option
              v-for="b in brandOptions"
              :key="b.id"
              :label="b.name + ' ( ' + b.id + ' )'"
              :value="b.id"></el-option>
          </el-select>
        </div>
      </div>
      <ul class="navbar_box">
        <div v-for="(item, index) in navData" :key="index">
          <li
            @click="go(item.path)"
            :class="
              'nav_items ' + (currentCom == item.path ? 'active_nav' : '')
            ">
            <span class="n_name">{{ item.name }}</span>
          </li>
        </div>
      </ul>
    </div>
    <router-view></router-view>
    <!-- 主体内容 组件区域 -->
    <div class="component_box">
      <!-- keep-live 中的exclude跟一个字符串或数组，内容为组件名称，则这些组件不会被keep-live缓存 -->
      <keep-alive :exclude="['taskDetail', 'singleTaskList']">
        <component
          :is="currentCom"
          :currentBrand="currentBrand"
          :taskComParams="taskComParams"
          :currentBrandName="
            brandOptions.filter((item) => item.id == currentBrand)[0].name
          "
          :databaseOptions="databaseOptions"
          :controlPriceList="allControlPrice"
          v-if="
            (currentBrand && currentCom != 'dataList') ||
            (currentCom == 'dataList' &&
              currentBrand &&
              databaseOptions.length > 0) ||
            (currentCom == 'priceControl' &&
              currentBrand &&
              allControlPrice.length > 0)
          "
          @getDatabaseAgain="getDBList"
          @getNewCPL="getCPLibrary"
          @toggleCom="toggle">
        </component>
        <el-empty
          description="暂无内容，请先创建品牌基本信息"
          v-else></el-empty>
      </keep-alive>
      <!-- <component :is="currentCom" :currentBrand="currentBrand"></component> -->
    </div>
  </div>
</template>

<script>
import brandInfo from "./components/brandInfo/brandInfo.vue"; // 品牌信息
import taskSubmission from "./components/taskSubmission/taskSubmission.vue"; // 任务列表
import taskDetail from "./components/taskSubmission/taskDetail.vue"; // 任务详情
import singleTaskList from "./components/taskSubmission/singleTaskList.vue"; // 单条任务详情
import dataList from "./components/dataList/dataList.vue"; // 数据列表
import whiteList from "./components/whiteList/whiteList.vue"; // 白名单
import priceControl from "./components/price/priceControl.vue"; // 控价表
import complaintsRecord from "./components/complaint/complaintsRecord.vue"; // 投诉记录
import generateReport from "./components/generateReport/index.vue"; // 生成报表
export default {
  components: {
    brandInfo,
    taskSubmission,
    taskDetail,
    singleTaskList,
    dataList,
    whiteList,
    priceControl,
    complaintsRecord,
    generateReport,
  },
  data() {
    return {
      userId: "",
      navData: [
        {
          name: "品牌信息",
          path: "brandInfo",
        },
        {
          name: "任务列表",
          path: "taskSubmission",
        },
        {
          name: "数据列表",
          path: "dataList",
        },
        {
          name: "白名单",
          path: "whiteList",
        },
        {
          name: "控价表",
          path: "priceControl",
        },
        {
          name: "投诉记录",
          path: "complaintsRecord",
        },
        {
          name: "生成报表",
          path: "generateReport",
        },
      ],
      brandOptions: [],
      currentCom: "", // 当前展示的组件
      currentBrand: "", // 当前的品牌
      emptyState: false, // 空状态 --- 如果没有品牌信息，展示空状态

      allDatabase: [], // 所有品牌数据库配置
      allControlPrice: [],

      taskComParams: {}, // 任务组件切换所需参数
    };
  },
  created() {
    this.userId = this.utils.sessionGetStore("userId");
    this.currentCom =
      this.utils.sessionGetStore("serviceCurrentCom") || "brandInfo";

    // 获取品牌信息
    this.getBrandInfo(
      function () {
        // 获取query中的品牌信息,
        // 否则
        // 获取到本地缓存的品牌id，
        // 否则
        // 默认展示品牌选项中的第一个
        let r = this.$route.query.currentBrand
          ? JSON.parse(this.$route.query.currentBrand)
          : false;
        let cb = this.utils.sessionGetStore("currentBrandIdCache")
          ? JSON.parse(this.utils.sessionGetStore("currentBrandIdCache"))
          : false;
        if (r) {
          this.currentBrand = r;
        } else if (cb) {
          this.currentBrand = cb;
        } else {
          if (this.brandOptions.length > 0) {
            this.currentBrand = this.brandOptions[0].id;
            this.emptyState = false;
            this.utils.sessionSetStore(
              "currentBrandIdCache",
              this.currentBrand
            );
          } else {
            // 没有品牌信息，显示空状态
            this.emptyState = true;
          }
        }

        this.getDBList();
        this.getCPLibrary();
      }.bind(this)
    );
  },
  computed: {
    databaseOptions() {
      return this.allDatabase.filter(
        (item) => item.brand_id == this.currentBrand
      );
    },
  },
  methods: {
    // 切换组件
    toggle(params) {
      this.taskComParams = { ...params };
      this.currentCom = params.target;
    },
    // 品牌切换
    brandChange(e) {
      // 如果手动选择品牌，就删除通过品牌列表传来的brand_id
      this.$router.push({ query: {} });
      this.utils.sessionSetStore("currentBrandIdCache", e);
      // 品牌切换，更新所属控价表库
      this.getCPLibrary();
    },
    go(path) {
      this.currentCom = path;
      this.utils.sessionSetStore("serviceCurrentCom", path);
    },

    // 获取品牌数据库
    getDBList() {
      let paramObj = {
        // 不传品牌，返回所有数据库信息
        // brand_id: this.currentBrand
      };
      this.$api
        .getDatabase(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code === 0) {
            this.allDatabase = [...res.data.list];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 获取品牌控价表库
    getCPLibrary() {
      let paramObj = {
        brand_id: this.currentBrand,
        user_id: this.userId,
      };
      this.$api.getCPLibrary(paramObj).then((res) => {
        if (res.code === 0) {
          this.allControlPrice = [...res.data.list];
        }
      });
    },

    // 获取品牌信息
    getBrandInfo(callBack) {
      let paramObj = {
        user_id: this.userId,
      };
      this.$api
        .brandManageList(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            res.data.list.forEach((item) => {
              this.brandOptions.push({
                name: item.brand_name,
                id: item.id,
              });
            });
            // this.brandOptions = [] // 测试空状态
            if (callBack) {
              callBack();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.emptyState = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  height: 100%;
  position: relative;

  .second-nav {
    width: 100%;
    height: 50px;
    // position: relative;
    position: fixed;
    top: 50px;
    z-index: 1000;

    .brand {
      // background: chartreuse;
      height: 50px;
      //   width: 250px;
      position: absolute;

      .b_style {
        height: 100%;
        padding: 10px;

        > span {
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }

        .b_select {
          width: 250px;
        }
      }
    }
    .navbar_box {
      background: #fff;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: center;

      .nav_items {
        padding: 0 10px;
        // background: cornflowerblue;
        line-height: 50px;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }

        .n_name {
          color: #000;
        }
      }
      .active_nav {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .component_box {
    padding-top: 60px;
    margin: 10px;
    margin-top: 0;
  }
}
</style>
