<!-- 店铺侵权排行榜 -->
<template>
  <div class="shop-infringe-ranking">
    <div class="title">店铺侵权黑榜</div>
    <tablePageComp
      :dataList="shopInfringeList"
      :totalCount="total"
      :columnsList="columnsList"
      :getDataConfig="dataQueryFunc()"
      :updateDataTag="updateDataTag"
      :orderNumConfig="{
        label: '排名',
      }"
      :pageSizeConfig="{
        layoutConfig: 'prev,pager,next',
        pageSize: 10,
        'pager-count': 5,
      }"
      :tableHeight="300">
    </tablePageComp>
  </div>
</template>

<script>
export default {
  name: "shopInfringeRanking",
  props: ["brand_id", "history_id"],
  watch: {
    history_id() {
      this.updateDataTag = !this.updateDataTag;
    },
  },
  data() {
    return {
      // 店铺侵权排行榜数据
      shopInfringeList: [],
      total: 0,
      updateDataTag: false,
      columnsList: [
        {
          label: "店铺名称",
          prop: "shop_name",
        },
        {
          label: "侵权总数",
          prop: "amount",
        },
        // {
        //   label: "侵权占比",
        //   prop: "percentage",
        // },
      ],
    };
  },
  methods: {
    dataQueryFunc() {
      let paramObj = {
        brand_id: this.brand_id,
        history_id: this.history_id,
      };
      return {
        apiName: "getShopInfringeRank",
        paramObj,
        success: function (res, callback) {
          console.log("============", res);
          this.shopInfringeList = res.data.list;
          this.total = res.data.count;
          callback();
        }.bind(this),
        failed: function (err, callback) {
          console.log(err);
          callback();
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-infringe-ranking {
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    height: 50px;
    line-height: 50px;
  }
}
</style>
