<!-- 各平台各类维权手段处理量展示 -->
<!-- 柱状图 -->
<template>
  <div class="handling-plt">
    <div id="HandingChart"></div>
  </div>
</template>

<script>
export default {
  name: "handlingPlt",
  props: ["brand_id", "history_id"],
  watch: {
    history_id() {
      this.getDataFunc();
    },
  },
  data() {
    return {
      chart: null,
      dataList: [],
    };
  },

  mounted() {
    this.getDataFunc();
    window.addEventListener("resize", () => {
      this.chart.resize();
    });
  },
  methods: {
    getDataFunc() {
      this.$api
        .getHandlePltFunc({
          brand_id: this.brand_id,
          history_id: this.history_id,
        })
        .then((res) => {
          console.log("=-==-=-==--=-=", res);
          this.dataList = res.data.list;
          this.initChart();
        });
      // this.dataList = [
      //   {
      //     platform: "淘宝",
      //     inform_number: 1000, //通知数
      //     out_number: 10000, // 下架数
      //     correct_number: 200, // 整改数
      //     complaint_number: 100, // 投诉数
      //   },
      //   {
      //     platform: "京东",
      //     inform_number: 1000, //通知数
      //     out_number: 10000, // 下架数
      //     correct_number: 200, // 整改数
      //     complaint_number: 100, // 投诉数
      //   },
      //   {
      //     platform: "拼多多",
      //     inform_number: 1000, //通知数
      //     out_number: 10000, // 下架数
      //     correct_number: 200, // 整改数
      //     complaint_number: 100, // 投诉数
      //   },
      //   {
      //     platform: "抖音",
      //     inform_number: 1000, //通知数
      //     out_number: 10000, // 下架数
      //     correct_number: 200, // 整改数
      //     complaint_number: 100, // 投诉数
      //   },
      // ];
    },
    initChart() {
      let option = {
        title: {
          text: "各平台各类维权手段处理量",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["通知数", "下架数", "整改数", "投诉数"],
          bottom: 0,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.dataList.map((item) => item.platform),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "通知数",
            type: "bar",
            barWidth: "20%",
            barGap: 0,
            data: this.dataList.map((item) => item.inform_number),
          },
          {
            name: "下架数",
            type: "bar",
            barWidth: "20%",
            barGap: 0,
            data: this.dataList.map((item) => item.out_number),
          },
          {
            name: "整改数",
            type: "bar",
            barWidth: "20%",
            barGap: 0,
            data: this.dataList.map((item) => item.correct_number),
          },
          {
            name: "投诉数",
            type: "bar",
            barWidth: "20%",
            barGap: 0,
            data: this.dataList.map((item) => item.complaint_number),
          },
        ],
      };

      this.chart = this.$echarts.init(
        document.getElementById("HandingChart"),
        "dark"
      );
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.handling-plt {
  // width: 50%;
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
