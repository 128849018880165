<!-- 当前品牌投诉手段占比 -->
<!-- 接口好像还没有 -->
<template>
  <div class="mean-of-complaint">
    <div id="ComplaintChart"></div>
  </div>
</template>

<script>
export default {
  name: "curBrandMeanOfComplaint",
  props: ["brand_id", "history_id"],
  watch: {
    history_id() {
      this.getDataFunc();
    },
  },
  data() {
    return {
      chart: null,
      dataList: [],
      dictionaries: [
        {
          value: 0,
          label: "未举报",
        },
        {
          value: 1,
          label: "工商举报",
        },
        {
          value: 2,
          label: "全网举报",
        },
        {
          value: 3,
          label: "全网举报/工商举报",
        },
        {
          value: 4,
          label: "知产投诉",
        },
      ],
    };
  },
  mounted() {
    this.getDataFunc();
    window.addEventListener("resize", () => {
      this.chart.resize();
    });
  },
  methods: {
    getDataFunc() {
      this.$api
        .getBrandMeanOfComplaintFunc({
          brand_id: this.brand_id,
          history_id: this.history_id,
        })
        .then((res) => {
          console.log("=========", res);
          this.dataList = res.data.list.map((item) => {
            let label = this.dictionaries.find(
              (i) => i.value == item.report
            )?.label;
            return {
              ...item,
              label,
            };
          });
          this.initChart();
        });
      this.dataList = [
        {
          report: "未举报",
          percentage: 0.1,
          amount: 20,
        },
        {
          report: "工商举报",
          percentage: 0.1,
          amount: 20,
        },
        {
          report: "全网举报",
          percentage: 0.1,
          amount: 20,
        },
        {
          report: "知识产权投诉",
          percentage: 0.1,
          amount: 20,
        },
      ];
    },
    initChart() {
      let options = {
        title: {
          text: "投诉手段占比",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.dataList.map((item) => {
              return {
                value: item.amount,
                name: item.label,
              };
            }),
            label: {
              show: true,
              position: "outside",
              formatter: "{b}：{d}%",
              overflow: "break",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.chart = this.$echarts.init(
        document.getElementById("ComplaintChart"),
        "dark"
      );
      this.chart.setOption(options);
    },
  },
};
</script>

<style lang="scss" scoped>
.mean-of-complaint {
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
