import Vue from "vue";
import VueRouter from "vue-router";
import utils from "@/libs/utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index_view",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/cp",
    name: "cp",
    component: () => import("@/views/changePassword.vue"),
  },
  {
    path: "/index_view",
    // name: 'index_view',
    component: () => import("../views/index.vue"),
    children: [
      {
        path: "/",
        redirect: "workbench",
      },
      {
        path: "workbench",
        name: "workbench",
        component: () => import("../views/workbench/index.vue"),
      },
    ],
  },
  // 品牌管理模块
  {
    path: "/brand_view",
    // name: 'brand_view',
    component: () => import("@/views/index"),
    children: [
      {
        path: "/",
        redirect: "brand_manage",
      },
      {
        path: "brand_manage",
        name: "brand_manage",
        component: () => import("@/views/brandManage/index"),
      },
    ],
  },
  // 服务模块
  {
    path: "/service_view",
    // name: 'service_view',
    component: () => import("@/views/index"),
    children: [
      {
        path: "/",
        redirect: "brand_info_aggregation",
      },
      {
        path: "brand_info_aggregation",
        name: "brand_info_aggregation",
        component: () => import("@/views/serviceModule/index"),
      },
      // 任务信息编辑
      {
        path: "taskInfoEdit",
        name: "taskInfoEdit",
        component: () =>
          import(
            "@/views/serviceModule/components/taskSubmission/taskInfoEdit"
          ),
      },
      // // 任务详情
      // {
      //   path: 'taskDetail',
      //   name: 'taskDetail',
      //   component: () => import('@/views/serviceModule/components/taskSubmission/taskDetail')
      // },
      // // 单次任务详情信息
      // {
      //   path: 'single_task_info_list',
      //   name: 'single_task_info_list',
      //   component: () => import('@/views/serviceModule/components/taskSubmission/singleTaskList')
      // },
    ],
  },

  // 报表预览
  {
    path: "/report_preview/:token",
    name: "report_preview",
    component: () => import("@/views/reportPreview/index"),
  },
];

/*
  在Vue-router≥3.0版本中，路由回调形式已经改成了Promise API形式，返回一个Promise。
  在相同路由转跳并且没有捕获错误时会报：Uncaught (in promise) Error: Avoided redundant navigation to current location: "xxxxx".
*/
// 包装路由函数,添加处理错误方法,解决连续触发两次路由，报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // 报表预览页面，跳过登录验证
  if (to.path.indexOf("report_preview") !== -1) {
    next();
  } else {
    let token = utils.sessionGetStore("token");
    if (token) {
      next();
    } else {
      // 没有登录信息
      if (to.path.indexOf("login") !== -1) {
        // 目标路由是登录，放行
        next();
      } else {
        // 目标路由不是登录，禁行，跳转到登录
        next({
          path: "/login",
        });
      }
    }
    next();
  }
});

router.afterEach(() => {});

export default router;
