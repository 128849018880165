<!-- 各平台破价率 -->
<!-- 饼图展示平台占比 -->
<!-- 柱状图 展示数量 -->
<template>
  <div class="plt-break-percentage">
    <div id="chartPieDom"></div>
    <div id="chartBarDom"></div>
  </div>
</template>

<script>
export default {
  name: "pltBreakPercentage",
  props: ["brand_id", "history_id"],
  watch: {
    history_id() {
      this.chartRenderFunc();
    },
  },
  data() {
    return {
      dataList: [],
      chartPieDom: null,
      chartBarDom: null,
    };
  },
  mounted() {
    this.chartRenderFunc();
    window.addEventListener(
      "resize",
      function () {
        this.chartPieDom.resize();
        this.chartBarDom.resize();
      }.bind(this)
    );
  },
  methods: {
    chartRenderFunc() {
      this.$api
        .getPltBreakDataFunc({
          brand_id: this.brand_id,
          history_id: this.history_id,
        })
        .then((res) => {
          console.log("---------", res);
          this.dataList = res.data.list;
          this.pieChartRenderFunc();
          this.barChartRenderFunc();
        });
    },
    pieChartRenderFunc() {
      let option = {
        title: {
          text: "各平台破价率",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: `{b}破价量：{c}`,
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left",
        // },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.dataList.map((item) => {
              return {
                value: item.amount,
                name: item.platform,
              };
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
          },
        ],
      };

      this.chartPieDom = this.$echarts.init(
        document.getElementById("chartPieDom"),
        "dark"
      );
      this.chartPieDom.setOption(option);
    },
    barChartRenderFunc() {
      let axisOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.dataList.map((item) => item.platform),
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "侵权数量",
            type: "bar",
            barWidth: "60%",
            label: {
              show: true,
              formatter: "侵权数量 {c}",
              color: "#fff",
              fontFamily: "monospace",
              overflow: "break",
              width: 100,
            },
            data: this.dataList.map((item) => item.amount),
          },
        ],
      };

      this.chartBarDom = this.$echarts.init(
        document.getElementById("chartBarDom"),
        "dark"
      );
      this.chartBarDom.setOption(axisOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.plt-break-percentage {
  width: 100%;
  height: 100%;
  display: flex;
  > div {
    width: 50%;
    height: 100%;
  }
}
</style>
