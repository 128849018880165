<!-- 非/白名单投诉/通知 量展示 -->
<template>
  <div class="complaint-white-list">
    <div id="chartRender"></div>
  </div>
</template>

<script>
export default {
  name: "complaintWhiteList",
  props: ["brand_id", "history_id"],
  watch: {
    history_id() {
      this.getDataFunc();
    },
  },
  data() {
    return {
      chart: null,
      dataList: [],
    };
  },
  mounted() {
    this.getDataFunc();
    window.addEventListener("resize", () => {
      this.chart.resize();
    });
  },
  methods: {
    getDataFunc() {
      this.$api
        .getComplaintWhiteFunc({
          brand_id: this.brand_id,
          history_id: this.history_id,
        })
        .then((res) => {
          console.log("-------------------", res);
          this.dataList = [];
          res.data.list.forEach((item) => {
            this.dataList.push({
              ...item,
              label: item.is_white === "1" ? "白名单" : "非白名单",
            });
          });
          this.initChart();
        });
    },
    initChart() {
      let option = {
        title: {
          text: "非/白名单处理量展示",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.dataList.map((item) => item.label),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "通知量",
            type: "bar",
            barWidth: "25%",
            barGap: 0,
            label: {
              show: true,
              formatter: "通知量",
              color: "#fff",
            },
            data: this.dataList.map((item) => item.inform_number),
          },
          {
            name: "投诉量",
            type: "bar",
            barWidth: "25%",
            barGap: 0,
            label: {
              show: true,
              formatter: "投诉量",
              color: "#959595",
            },
            data: this.dataList.map((item) => item.complaint_number),
          },
        ],
      };
      this.chart = this.$echarts.init(
        document.getElementById("chartRender"),
        "dark"
      );
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.complaint-white-list {
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
