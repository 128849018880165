import axios from "axios";
import Vue from "../main.js";
import qs from "qs";

export default {
  // 埋点
  // param
  // operatingRecord 操作类型
  // port 接口地址
  // remark 备注
  buriedPoint: (data) => {
    if (data.port == "login") {
      data = qs.stringify(data);
    } else {
      data.nameId = window.sessionStorage.getItem("userId");
      data.name = window.sessionStorage.getItem("userName");
    }
    return Vue.$axios({
      url: "/galileo/operatingRecord",
      method: "post",
      data,
    });
  },
  login(param) {
    return Vue.$axios({
      url: `/galileo/login`,
      method: "post",
      params: param,
    })
      .then((response) => {
        // console.log(response)
        if (response.code === 0) {
          this.buriedPoint({
            operatingRecord: "登录",
            port: "login",
            nameId: response.data.list.id,
            name: response.data.list.username,
          });
        }
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 注册
  registerFunc(param) {
    return Vue.$axios({
      url: `/galileo/insertc_login`,
      method: "post",
      params: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 修改密码
  updatePasswordFunc(param) {
    return Vue.$axios({
      url: `/galileo/update_password`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  //////////////////////////
  /////// 品牌管理 ///////////
  ///////////////////////////
  // 品牌管理列表
  brandManageList(param) {
    return Vue.$axios({
      url: `/galileo/c_list`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 添加品牌信息
  addBrandManage(param) {
    return Vue.$axios({
      url: `/galileo/insertc_list`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "新建品牌",
          port: "insertc_list",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 修改品牌管理列表信息
  updateBrandManage(param) {
    return Vue.$axios({
      url: `/galileo/updatec_list`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  //////////////////////////
  /////// 服务模块 ///////////
  ///////////////////////////

  /////// 品牌信息 ///////////
  // 异常链接概况图表数据
  getExceLinkFunc(param) {
    return Vue.$axios({
      url: `/galileo/selectcount`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 获取任务队列信息
  getQueueNum() {
    return Vue.$axios({
      url: `/galileo/selectPlatformCount`,
      method: "get",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 品牌备注---查询
  getBrandRemarks(param) {
    return Vue.$axios({
      url: `/galileo/selectremarks`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 品牌备注---添加
  addBrandRemarks(param) {
    return Vue.$axios({
      url: `/galileo/insertremarks`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 品牌备注---删除
  deleteBrandRemarks(param) {
    return Vue.$axios({
      url: `/galileo/deleteremarks`,
      method: "delete",
      params: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 客户文件---查询
  getCustomFile(param) {
    return Vue.$axios({
      url: `/galileo/selectfile`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 客户文件---添加
  addCustomFile(param) {
    return Vue.$axios({
      url: `/galileo/insertfile`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 客户文件---删除
  deleteCustomFile(param) {
    return Vue.$axios({
      url: `/galileo/deletefile`,
      method: "delete",
      params: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  /////// 任务提交 ///////////
  // 获取积分
  subTaskIntegral(param) {
    return Vue.$axios({
      url: `/galileo/selectTaskCount`,
      method: "get",
      params: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 任务列表
  taskSubList(param) {
    let urlStr = `/galileo/select_task?pageNow=${param.pageNow}&pageSize=${param.pageSize}`;
    Object.keys(param).forEach((key) => {
      if (key != "pageNow" && key != "pageSize") {
        urlStr += `&${key}=${param[key]}`;
      }
    });
    return Vue.$axios({
      url: `/galileo/select_task`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 重新采集任务
  reloadTaskFunc(param) {
    return Vue.$axios({
      url: `/galileo/again_collection`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "重新采集",
          port: "again_collection",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 单次任务修改 传status = '1'(随便一个值)----重新采集
  //
  reloadSingleTask(param) {
    return Vue.$axios({
      url: `/galileo/updatetask_detail`,
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "重新采集",
        port: "updatetask_detail",
        remark: param.task_id,
      });
      return response;
    });
  },

  // 任务信息添加
  addTaskSubList(param) {
    return Vue.$axios({
      url: `/galileo/insertt_task`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "新建采集任务",
          port: "insertt_task",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 任务队列查询
  getTaskQUeue() {
    return Vue.$axios({
      url: `/galileo/selectUntreatedTask`,
      method: "get",
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "新建采集任务",
          port: "insertt_task",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 任务信息修改
  modifyTaskSubList(param) {
    return Vue.$axios({
      url: `/galileo/updatet_task`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 数据筛查
  gIDTestFunc(param) {
    return Vue.$axios({
      url: "/galileo/ImportGoods",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "数据筛查",
        port: "ImportGoods",
      });
      return response;
    });
  },

  // 任务执行信息详情
  taskExecutionFunc(param) {
    return Vue.$axios({
      url: `/galileo/select_task_detail`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 单次任务执行结果列表
  singleTaskInfoFunc(param) {
    return Vue.$axios({
      url: `/galileo/select_goods_info`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 单次任务信息修改
  modifySingleTaskFunc(param) {
    return Vue.$axios({
      url: `/galileo/update_goods_info`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "任务-数据操作",
          port: "update_goods_info",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 单次任务执行结果添加收录
  includedFunc(param) {
    return Vue.$axios({
      url: `/galileo/insert_goods_info_low`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "任务-收录",
          port: "insert_goods_info_low",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 单条链接取消收录
  cancelInclub(param) {
    return Vue.$axios({
      url: `/galileo/deletetask_list`,
      method: "post",
      params: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "单条取消收录",
        port: "deletetask_list",
      });
      return response;
    });
  },

  // 导出单次任务数据
  exportSingleTaskFunc(param) {
    return Vue.$axios({
      url: "/galileo/outinfolist",
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "任务-导出数据",
          port: "outinfolist",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 匹配无关项
  matchUnreleteFunc(param) {
    return Vue.$axios({
      url: "/galileo/task_tb",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "无关项筛查",
        port: "task_tb",
      });
      return response;
    });
  },

  // 检测所选平台及所选任务类型是否可用
  testPlatformAndType(param) {
    return Vue.$axios({
      url: "/galileo/selectTaskMaintain",
      method: "post",
      data: param,
    }).then((response) => {
      return response;
    });
  },

  //////// 数据列表 ////////////

  // 获取数据列表
  getDataListFunc(param) {
    return Vue.$axios({
      url: `/galileo/select_goods_info_low`,
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 修改数据列表
  modifyDataListFunc(param) {
    return Vue.$axios({
      // url: `/galileo/update_goods_info_low`,
      url: `/galileo/update_goods_info_data`,
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-批量操作",
          port: "update_goods_info_data",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 批量删除数据列表信息
  deleteDataListFunc(param) {
    return Vue.$axios({
      url: "/galileo/deletelist",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-批量删除",
          port: "deletelist",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 获取自定义表头信息
  customTableHead(param) {
    return Vue.$axios({
      url: `/galileo/selectlist_name`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 设置自定义表头
  setupTableHead(param) {
    return Vue.$axios({
      url: `/galileo/insertlist_name`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "表头设置",
          port: "insertlist_name",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 更新数据前检查
  beforeUpdateFunc(param) {
    return Vue.$axios({
      url: "/galileo/selectNotUpdateGoodsCount",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // 更新数据
  updateDataListFunc(param) {
    return Vue.$axios({
      url: "/galileo/is_update",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-更新功能",
          port: "is_update",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 停止更新
  stopUpdateDataListFunc(param) {
    return Vue.$axios({
      url: "/galileo/pauseUpdateGoodsList",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-停止更新功能",
          port: "pauseUpdateGoodsList",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 标签管理
  // 查询
  getLabelFunc(param) {
    return Vue.$axios({
      url: `/galileo/label`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 添加
  addLabelFunc(param) {
    return Vue.$axios({
      url: `/galileo/insert_label`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-标签管理",
          port: "insert_label",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 删除
  deleteLabelFunc(param) {
    return Vue.$axios({
      url: `/galileo/delete_label`,
      // 只修改了状态，不使用delete
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-标签管理",
          port: "delete_label",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 数据列表导出
  exportDataListFunc(param) {
    return Vue.$axios({
      url: "/galileo/outlist",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-导出数据",
          port: "outlist",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  importDatalistFunc(param) {
    return Vue.$axios({
      url: "/galileo/import_list",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-导入数据",
          port: "import_list",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 2023-5-15 导入更新
  importDatalistFunc_new(param) {
    return Vue.$axios({
      url: "/galileo/importDataList",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-导入数据",
          port: "importDataList",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  ////////// 控价表 /////////////
  // 获取控价表
  getControlPriceFunc(param) {
    return Vue.$axios({
      url: `/galileo/select_low_price`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 添加控价表
  addControlPriceFunc(param) {
    return Vue.$axios({
      url: "/galileo/insert_low",
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "操作控价表",
          port: "import_list",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 导入控价表
  importControlPriceFunc(param) {
    return Vue.$axios({
      url: "/galileo/import_low",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "操作控价表",
          port: "import_list",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 删除控价表
  deleteControlPriceFunc(param) {
    return Vue.$axios({
      url: `/galileo/delete_low`,
      method: "delete",
      params: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "操作控价表",
          port: "delete_low",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 获取控价表库
  getCPLibrary(param) {
    return Vue.$axios({
      url: `/galileo/select_price_table`,
      method: "post",
      data: param,
    });
  },
  // 新建控价表库
  createCPLibrary(param) {
    return Vue.$axios({
      url: `/galileo/create_new_price_table`,
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "操作控价表",
        port: "create_new_price_table",
      });
      return response;
    });
  },
  // 删除控价表库
  deleteCPLibrary(param) {
    return Vue.$axios({
      url: "/galileo/del_price_table",
      method: "delete",
      params: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "操作控价表",
        port: "del_price_table",
      });
      return response;
    });
  },

  ////////// 白名单 /////////////
  // 获取白名单
  getWhiteListFunc(param) {
    return Vue.$axios({
      url: `/galileo/select_white_price`,
      method: "post",
      data: param,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 添加白名单
  addWhiteListFunc(param) {
    return Vue.$axios({
      url: "/galileo/insert_white",
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "操作白名单",
          port: "insert_white",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 导入白名单
  importWhiteListFunc(param) {
    return Vue.$axios({
      url: "/galileo/import_white",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "操作白名单",
          port: "import_white",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // 删除白名单
  deleteWhiteListFunc(param) {
    return Vue.$axios({
      url: `/galileo/delete_white`,
      method: "delete",
      params: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "操作白名单",
          port: "delete_white",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 清空当前品牌白名单
  emptyBrandWLFunc(param) {
    return Vue.$axios({
      url: `/galileo/deleteWhiteList`,
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: `清空白名单,brand:${param.brand_id}`,
          port: "deleteWhiteList",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 同步白名单控价表
  syncWhiteControl(param) {
    return Vue.$axios({
      url: "/galileo/synchronization",
      method: "post",
      data: param,
    })
      .then((response) => {
        this.buriedPoint({
          operatingRecord: "数据-同步功能",
          port: "synchronization",
        });
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  // 投诉记录模块
  // 列表
  getComplaintRecord(param) {
    return Vue.$axios({
      url: "/galileo/selectcomplaint_list",
      method: "post",
      data: param,
    });
  },

  addComplaintRecord(param) {
    return Vue.$axios({
      url: "/galileo/insertcomplaint_list",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "投诉记录操作",
        port: "insertcomplaint_list",
      });
      return response;
    });
  },

  modifyComplaintRecord(param) {
    return Vue.$axios({
      url: "/galileo/updatecomplaint_list",
      method: "post",
      data: param,
    });
  },

  deleteComplaintRecord(param) {
    return Vue.$axios({
      url: "/galileo/deletecomplaint_list",
      method: "delete",
      params: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "投诉记录操作",
        port: "deletecomplaint_list",
      });
      return response;
    });
  },

  // 投诉记录趋势图
  complaintTrendChart(param) {
    return Vue.$axios({
      url: "/galileo/selectcomplaint_data",
      method: "post",
      data: param,
    });
  },

  // 投诉类型占比
  complaintPPChart(param) {
    return Vue.$axios({
      url: "/galileo/selectcomplaint_proportion",
      method: "post",
      data: param,
    });
  },

  // 导入投诉记录
  importComplaintFunc(param) {
    return Vue.$axios({
      url: "/galileo/import_Complaint_listVO",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "投诉记录操作",
        port: "import_Complaint_listVO",
      });
      return response;
    });
  },

  // 导出投诉记录
  exportComplaint(param) {
    return Vue.$axios({
      url: "/galileo/outinComplaint_listVO",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "投诉记录操作",
        port: "outinComplaint_listVO",
      });
      return response;
    });
  },

  // 工作台统计
  workbenchStatisFunc(param) {
    return Vue.$axios({
      url: "/galileo/work",
      method: "post",
      data: param,
    });
  },

  // 无关项管理
  // 查询无关项
  getIrrelevant(param) {
    return Vue.$axios({
      url: "/galileo/select_Irrelevant_list",
      method: "post",
      data: param,
    });
  },
  // 删除无关项
  delIrrelevant(param) {
    return Vue.$axios({
      url: "/galileo/delect_Irrelevant_list",
      method: "post",
      params: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "操作无关项",
        port: "delect_Irrelevant_list",
      });
      return response;
    });
  },
  // 导入无关项
  importIrrelevant(param) {
    return Vue.$axios({
      url: "/galileo/import_Irrelevant",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "操作无关项",
        port: "import_Irrelevant",
      });
      return response;
    });
  },

  // 导出无关项列表
  exportIrrelevant(param) {
    return Vue.$axios({
      url: "/galileo/export_Irrelevant",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "操作无关项",
        port: "export_Irrelevant",
      });
      return response;
    });
  },

  // 清空无关项
  emptyIrrelevant(param) {
    return Vue.$axios({
      url: "/galileo/delect_Irrelevant_OneKey",
      method: "post",
      params: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "操作无关项",
        port: "delect_Irrelevant_OneKey",
      });
      return response;
    });
  },

  // 数据库管理
  // 添加新的数据库
  addDatabase(param) {
    return Vue.$axios({
      url: "/galileo/insertincome_num",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "数据库管理",
        port: "insertincome_num",
      });
      return response;
    });
  },

  // 查询
  getDatabase(param) {
    return Vue.$axios({
      url: "/galileo/selecttincome_num",
      method: "post",
      data: param,
    });
  },

  // 删除
  deleteDatabase(param) {
    return Vue.$axios({
      url: "/galileo/deleteincome_num",
      method: "delete",
      params: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "数据库管理",
        port: "deleteincome_num",
      });
      return response;
    });
  },

  // 链接状态更新
  updateTestResult(param) {
    return Vue.$axios({
      url: "/galileo/updateLinkState",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(param),
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "链接检测",
        port: "updateLinkState",
      });
      return response;
    });
  },

  // 获取人员信息
  getEmployee() {
    return Vue.$axios({
      url: "/galileo/selectEmployee",
      method: "post",
    });
  },

  // 修改品牌所属人
  // id;//品牌id
  // oldUserId;//前任品牌负责人id
  // userId;//现任品牌负责人id
  modifyBrandOwner(param) {
    return Vue.$axios({
      url: "/galileo/UpdateBrandOwner",
      method: "post",
      data: param,
    }).then((response) => {
      this.buriedPoint({
        operatingRecord: "品牌所属人变更",
        port: "UpdateBrandOwner",
      });
      return response;
    });
  },

  //////// 生成报表功能  ///////////
  /////////////////////////////////
  // 查询报表上传记录
  getRecordUploadList(param) {
    return Vue.$axios({
      url: "/galileo/selectDataComplaintListRecord",
      method: "post",
      data: param,
    });
  },

  // 上传报表
  uploadReportFunc(param) {
    return Vue.$axios({
      url: "/galileo/importDataComplaintList",
      method: "post",
      data: param,
    });
  },

  // 生成分享链接
  generateShareLinkFunc(param) {
    return Vue.$axios({
      url: "/galileo/insertShareLink",
      method: "post",
      data: param,
    });
  },

  // 获取侵权处理总销售额
  getRecoverDamage(param) {
    return Vue.$axios({
      url: "/galileo/selectTotalSalesTable",
      method: "post",
      data: param,
    });
  },

  // 店铺侵权黑榜
  getShopInfringeRank(param) {
    return Vue.$axios({
      url: "/galileo/selectShopComplaintListTop",
      method: "post",
      data: param,
    });
  },

  // 各平台破价率
  getPltBreakDataFunc(param) {
    return Vue.$axios({
      url: "/galileo/selectPlatformComplaintLow",
      method: "post",
      data: param,
    });
  },

  // 各平台各类处理方式量
  getHandlePltFunc(param) {
    return Vue.$axios({
      url: "/galileo/selectPlatformStatisticalTable",
      method: "post",
      data: param,
    });
  },

  // 非/白名单处理量展示
  getComplaintWhiteFunc(param) {
    return Vue.$axios({
      url: "/galileo/selectWhiteStatisticalTable",
      method: "post",
      data: param,
    });
  },

  // 投诉手段占比
  getBrandMeanOfComplaintFunc(param) {
    return Vue.$axios({
      url: "/galileo/selectComplaintPercentageChart",
      method: "post",
      data: param,
    });
  },

  //删除报表记录
  deleteReportRecord(param) {
    return Vue.$axios({
      url: "/galileo/deleteDataComplaintListRecord",
      method: "post",
      data: param,
    });
  },

  // 解析token
  parseTokenFunc(param) {
    return Vue.$axios({
      url: "/galileo/analysisToken",
      method: "post",
      data: param,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },

  // 编辑简评
  editCommentFunc(param) {
    return Vue.$axios({
      url: "/galileo/updateDataComplaintListRecord",
      method: "post",
      data: param,
    });
  },

  // 获取报表简评
  getBriefCommentFunc(param) {
    return Vue.$axios({
      url: "/galileo/selectCommentByHistoryId",
      method: "post",
      data: param,
    });
  },
};
